import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getAgents, getChatSessions, createChatSession, sendChatMessage, getDojoLearnings, createDojoLearning, deleteDojoLearning } from '../../utils/api';

const DojoArena = () => {
  const history = useHistory();
  const [agents, setAgents] = useState([]);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [chatSessions, setChatSessions] = useState([]);
  const [currentSession, setCurrentSession] = useState(null);
  const [inputMessage, setInputMessage] = useState('');
  const [dojoLearnings, setDojoLearnings] = useState([]);
  const [newLearning, setNewLearning] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchAgents();
  }, []);

  useEffect(() => {
    if (selectedAgentId) {
      fetchChatSessions();
      fetchDojoLearnings();
    }
  }, [selectedAgentId]);

  const fetchAgents = async () => {
    try {
      const response = await getAgents();
      setAgents(response);
    } catch (error) {
      console.error('Error fetching agents:', error);
      setError('Failed to fetch agents. Please try again.');
    }
  };

  const fetchChatSessions = async () => {
    if (!selectedAgentId) return;
    try {
      const response = await getChatSessions(selectedAgentId);
      setChatSessions(Array.isArray(response) ? response : []);
      if (response.length > 0) {
        setCurrentSession(response[0]);
      }
    } catch (error) {
      console.error('Error fetching chat sessions:', error);
      setError('Failed to fetch chat sessions. Please try again.');
    }
  };

  const handleAgentChange = (event) => {
    const newAgentId = event.target.value;
    setSelectedAgentId(newAgentId);
    setCurrentSession(null);
    if (newAgentId) {
      history.push(`/dojo-arena/${newAgentId.toString()}`);
    }
  };

  const fetchDojoLearnings = async () => {
    if (!selectedAgentId) return;
    try {
      const response = await getDojoLearnings(selectedAgentId);
      setDojoLearnings(Array.isArray(response) ? response : []);
    } catch (error) {
      console.error('Error fetching dojo learnings:', error);
      setError('Failed to fetch dojo learnings. Please try again.');
    }
  };

  const handleCreateNewSession = async () => {
    if (!selectedAgentId) return;
    try {
      const newSession = await createChatSession(selectedAgentId);
      setChatSessions([...chatSessions, newSession]);
      setCurrentSession(newSession);
    } catch (error) {
      console.error('Error creating new chat session:', error);
      setError('Failed to create a new chat session. Please try again.');
    }
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim() || !currentSession) return;
  
    const newMessage = { role: 'user', content: [{ text: inputMessage }], timestamp: new Date().toISOString() };
    const updatedSession = {
      ...currentSession,
      messages: [...currentSession.messages, newMessage]
    };
    setCurrentSession(updatedSession);
    setInputMessage('');
  
    try {
      const response = await sendChatMessage(currentSession.id, inputMessage);
      const assistantMessage = { role: 'assistant', content: [{ type: 'text', text: response.response }], timestamp: new Date().toISOString() };
      const finalUpdatedSession = {
        ...updatedSession,
        messages: [...updatedSession.messages, assistantMessage]
      };
      setCurrentSession(finalUpdatedSession);
      setChatSessions(chatSessions.map(session => 
        session.id === currentSession.id ? finalUpdatedSession : session
      ));
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message. Please try again.');
    }
  };

  const handleSessionChange = (session) => {
    setCurrentSession(session);
  };

  const handleAddLearning = async () => {
    if (!newLearning.trim() || !selectedAgentId) return;

    try {
      const response = await createDojoLearning(selectedAgentId, { instruction: newLearning });
      setDojoLearnings([...dojoLearnings, response]);
      setNewLearning('');
    } catch (error) {
      console.error('Error adding learning:', error);
      setError('Failed to add new learning. Please try again.');
    }
  };

  const handleDeleteLearning = async (lessonId) => {
    if (!selectedAgentId) return;
    try {
      await deleteDojoLearning(selectedAgentId, lessonId);
      setDojoLearnings(dojoLearnings.filter(learning => learning.id !== lessonId));
    } catch (error) {
      console.error('Error deleting learning:', error);
      setError('Failed to delete learning. Please try again.');
    }
  };

  return (
    <div className="bg-black min-h-screen p-8 font-['Arimo'] text-white">
      <div className="bg-gradient-to-b from-[#ff5757] via-[#8c52ff] to-black pt-16 pb-24">
        <div className="max-w-7xl mx-auto px-8">
          <h1 className="text-6xl font-bold mb-2">Dojo Arena</h1>
          <p className="text-2xl opacity-80">Train your AI Expert</p>

          <select
            value={selectedAgentId || ''}
            onChange={handleAgentChange}
            className="mt-4 bg-[#121212] text-white border border-[#8c52ff] rounded-md p-2"
          >
            <option value="">Select an Agent</option>
            {agents.map((agent) => (
              <option key={agent._id.$oid} value={agent._id.$oid}>
                {agent.agent_name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {error && (
        <div className="max-w-7xl mx-auto mt-4 bg-red-600 text-white p-4 rounded-md">
          {error}
        </div>
      )}

      {selectedAgentId ? (
        <div className="max-w-7xl mx-auto mt-8 grid grid-cols-12 gap-8">
          {/* Chat Sessions */}
          <div className="col-span-3 bg-[#121212] rounded-[30px] p-6">
            <h2 className="text-2xl font-bold mb-4">Chat Sessions</h2>
            <button
              onClick={handleCreateNewSession}
              className="w-full bg-[#8c52ff] text-white rounded-full px-4 py-2 mb-4 hover:bg-[#7b46e6] transition-colors duration-200"
            >
              New Session
            </button>
            <ul className="space-y-2">
              {chatSessions.map((session) => (
                <li
                  key={session.id}
                  className={`cursor-pointer p-2 rounded ${
                    currentSession && currentSession.id === session.id
                      ? 'bg-[#1f1f1f]'
                      : 'hover:bg-[#1f1f1f]'
                  }`}
                  onClick={() => handleSessionChange(session)}
                >
                  {session.name || `Session ${session.id.slice(0, 8)}...`}
                </li>
              ))}
            </ul>
          </div>

          {/* Chat Interface */}
          <div className="col-span-6 bg-[#121212] rounded-[30px] p-6 flex flex-col h-[600px]">
            <div className="flex-grow overflow-y-auto mb-4">
              {currentSession && currentSession.messages.map((msg, index) => (
                <div
                  key={index}
                  className={`mb-4 ${
                    msg.role === 'user' ? 'text-right' : 'text-left'
                  }`}
                >
                  <span
                    className={`inline-block p-2 rounded-lg ${
                      msg.role === 'user'
                        ? 'bg-[#8c52ff] text-white'
                        : 'bg-[#1f1f1f] text-white'
                    }`}
                  >
                    {msg.content[0].text}
                  </span>
                </div>
              ))}
            </div>
            <div className="flex">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                className="flex-grow bg-[#1f1f1f] text-white rounded-l-full px-4 py-2 focus:outline-none"
                placeholder="Type your message..."
              />
              <button
                onClick={handleSendMessage}
                className="bg-[#8c52ff] text-white rounded-r-full px-6 py-2 hover:bg-[#7b46e6] transition-colors duration-200"
              >
                Send
              </button>
            </div>
          </div>

          {/* Dojo Learnings */}
          <div className="col-span-3 bg-[#121212] rounded-[30px] p-6">
            <h2 className="text-2xl font-bold mb-4">Dojo Learnings</h2>
            {dojoLearnings.length > 0 ? (
              <ul className="space-y-2 mb-4">
                {dojoLearnings.map((learning) => (
                  <li key={learning.id} className="flex justify-between items-center">
                    <span>{learning.content.instruction}</span>
                    <button
                      onClick={() => handleDeleteLearning(learning.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-400 mb-4">No learnings available.</p>
            )}
            <div className="flex">
              <input
                type="text"
                value={newLearning}
                onChange={(e) => setNewLearning(e.target.value)}
                className="flex-grow bg-[#1f1f1f] text-white rounded-l-lg px-4 py-2 focus:outline-none"
                placeholder="Add new learning..."
              />
              <button
                onClick={handleAddLearning}
                className="bg-[#4bb35d] text-white rounded-r-lg px-4 py-2 hover:bg-[#3d9c4e] transition-colors duration-200"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="max-w-7xl mx-auto mt-8 text-center">
          <p className="text-2xl text-gray-400">Please select an agent to begin.</p>
        </div>
      )}
    </div>
  );
};

export default DojoArena;