import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import SignUp from './components/Auth/SignUp';
import SignIn from './components/Auth/SignIn';
import AgentCreationForm from './components/AgentCreation/AgentCreationForm';
import FileUploadForm from './components/FileUpload/FileUploadForm';
import DeploymentForm from './components/Deployment/DeploymentForm';
import Dashboard from './components/Dashboard/Dashboard';
import GoogleDriveCallback from './components/FileUpload/GoogleDriveCallback';
import SlackCallback from './components/Deployment/SlackCallback';
import "@fontsource/arimo";
import 'material-icons/iconfont/material-icons.css';
import { Link } from 'react-router-dom';
import DojoArena from './components/DojoArena/DojoArena';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};

const Sidebar = ({ sidebarOpen, toggleSidebar }) => {
  const { user, logout } = useAuth();

  return (
    <nav className={`fixed left-0 top-0 h-full bg-black text-white p-4 transition-all duration-300 flex flex-col ${sidebarOpen ? 'w-64' : 'w-16'}`}>
      <div className="flex items-center mb-4 justify-between">
        {sidebarOpen && <img src="https://storage.googleapis.com/everlearningai-tmp-bucket/logos/logo-landscape.png" alt="Logo" className="h-16 w-auto max-w-full" />}
        <button onClick={toggleSidebar} className="text-white font-['Arimo'] text-2xl ml-auto">
          {sidebarOpen ? '<' : '>'}
        </button>
      </div>
      <ul className="flex-grow">
        <li className="mb-4">
          <Link to="/dashboard" className="text-gray-300 hover:text-white flex items-center">
            <span className="material-icons mr-2">psychology</span>
            {sidebarOpen && "Dashboard"}
          </Link>
        </li>

        <li className="mb-4">
  <Link to="/dojo-arena" className="text-gray-300 hover:text-white flex items-center">
    <span className="material-icons mr-2">school</span>
    {sidebarOpen && "Dojo Arena"}
  </Link>
</li>

        <li className="mb-4">
          <Link to="/create-agent" className="text-gray-300 hover:text-white flex items-center">
            <span className="material-icons mr-2">precision_manufacturing</span>
            {sidebarOpen && "Agent Builder"}
          </Link>
        </li>
      </ul>
      {user && (
        <div className="mt-auto">
          {sidebarOpen && (
            <p className="text-sm text-gray-400 mb-2">{user.fullname}</p>
          )}
          <button
            onClick={logout}
            className="flex items-center text-gray-300 hover:text-white"
          >
            <span className="material-icons mr-2">logout</span>
            {sidebarOpen && "Logout"}
          </button>
        </div>
      )}
    </nav>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

const AppContent = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { user, loading } = useAuth();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex min-h-screen font-['Arimo'] bg-black">
      {user && (
        <Sidebar 
          sidebarOpen={sidebarOpen} 
          toggleSidebar={toggleSidebar} 
        />
      )}
      <main className={`flex-1 overflow-y-auto bg-black ${user ? (sidebarOpen ? 'ml-64' : 'ml-16') : 'w-full'}`}>
        <Switch>
          <Route exact path="/" render={() => (user ? <Redirect to="/dashboard" /> : <Redirect to="/signin" />)} />
          <Route path="/signup" component={SignUp} />
          <Route path="/signin" component={SignIn} />


          <Route path="/dojo-arena/:agentId?" component={DojoArena} />

          
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/create-agent" component={AgentCreationForm} />
          <PrivateRoute path="/upload" component={FileUploadForm} />

          <PrivateRoute path="/deploy" component={DeploymentForm} />

          <Route path="/auth/google/callback" component={GoogleDriveCallback} />
          <Route path="/auth/slack/callback" component={SlackCallback} />
          <Route path="*" render={() => <Redirect to="/" />} /> {/* Catch-all route */}
        </Switch>
      </main>
    </div>
  );
};

export default App;

//          
//           <Route path="/deploy" component={DeploymentForm} />